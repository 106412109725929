
























import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../../viewmodels/summary-viewmodel'

@Observer
@Component({
  components: {},
})
export default class Stats extends Vue {
  @Inject() vm!: SummaryViewModel
}
